<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h3 class="wizard-title">Buyurtma malumotlari</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h3 class="wizard-title">TTN malumotlari</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->

        <div class="row justify-content-center px-8 px-lg-10">
          <div class="col-12">
            <div v-if="loading" class="text-primary text-center my-5">
              <b-spinner
                style="width: 3rem; height: 3rem"
                label="Large Spinner"
              ></b-spinner>
            </div>
            <!--begin: Wizard Form-->
            <form v-if="!loading" class="form" id="kt_form">
              <div
                data-wizard-type="step-content"
                data-wizard-state="current"
                class="pb-5"
              >
                <div class="row flex-wrap">
                  <div class="col-4">
                    <div class="form-group">
                      <label>Nomi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.zayavka_owner"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Zayavka Status</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.zayavka_status"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Postavshik</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="
                          list.deliver == 'POS'
                            ? 'Boshqalar'
                            : getCurrZayavka.deliver
                        "
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Narxi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="numberWithSpaces(list.price) || 0"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>NDS summa</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="numberWithSpaces(list.nds_summa) || 0"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>NDS bilan Narxi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="numberWithSpaces(list.price_with_nds) || 0"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Yuk ketish sanasi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.start_date"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Yuk yetib borish sanasi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.end_date"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Yetqazib berilgan sana</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.delivered_date | formatDate"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Kg</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.post_kg"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>m3</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.post_m3"
                      />
                    </div>
                  </div>
                  <div
                    class="col-4"
                    v-if="
                      list.zayavka_distance !== null ||
                      list.zayavka_distance !== undefined
                    "
                  >
                    <div class="form-group">
                      <label>Zayavka km</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.zayavka_distance"
                      />
                    </div>
                  </div>
                  <div
                    class="col-4"
                    v-if="
                      list.direction_cities_names !== null ||
                      list.direction_cities_names !== undefined
                    "
                  >
                    <div class="form-group">
                      <label>Yo'nalish</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="list.direction_cities_names"
                      />
                    </div>
                  </div>
                </div>
                <!-- Driver info -->
                <div v-if="list.truck !== null">
                  <div
                    v-if="list.deliver == 'BTS' || list.truck.driver == null"
                  >
                    <h4 class="text-dark mb-5">Haydovchi malumotlari</h4>
                    <div class="row flex-wrap">
                      <div class="col-6">
                        <div
                          class="form-group"

                        >
                          <label>Asosiy Haydovchi</label>
                          <input
                            disabled
                            type="text"
                            class="
                              form-control form-control-solid form-control-lg
                            "
                            :value="list.main_driver"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div
                          class="form-group"

                        >
                          <label>Yordamchi</label>
                          <input
                            disabled
                            type="text"
                            class="
                              form-control form-control-solid form-control-lg
                            "
                            :value="list.assistant_driver"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="mb-5"
                  v-if="
                    list.zayavka_fotoreports !== null &&
                    list.zayavka_fotoreports !== undefined
                  "
                >
                  <h4 class="text-dark mb-5">Fotochet</h4>

                  <div>
                    <div class="gallery">
                      <div
                        class="gallery__item"
                        v-for="image in getCurrZayavkaImg"
                        :key="image.id"
                      >
                        <img :src="image" alt="" />
                        <div class="download" @click="downImg(image)">
                          <button class="btn btn-primary">Yuklash</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label>Izoh</label>
                    <input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      disabled
                      :value="list.comment"
                    />
                  </div>
                </div>

                <!-- Provider Info -->
                <div v-if="list.deliver == 'POS'">
                  <h4 class="text-dark mb-5">Postavshik malumotlari</h4>
                  <div class="row flex-wrap">
                    <div class="col-4">
                      <div class="form-group">
                        <label> Postavshik shartnomasi</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.provider_name"
                        />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="form-group">
                        <label> Postavshik shartnomasi raqami</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.provider_contract_number"
                        />
                      </div>
                    </div>

                    <div class="col-4" v-if="list.postavshik_driver">
                      <div class="form-group">
                        <label> Haydovchi</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_driver"
                        />
                      </div>
                    </div>

                    <div class="col-4">
                      <div class="form-group">
                        <label>Narxi</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="numberWithSpaces(list.postavshik_price) || 0"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Nds(Summa)</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="
                            numberWithSpaces(list.postavshik_nds_summa) || 0
                          "
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Narx (Nds blan)</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="
                            numberWithSpaces(list.postavshik_price_with_nds) ||
                            0
                          "
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Mashina modeli</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_truck_info"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Mashina raqami</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.postavshik_truck_number"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label>Buyurtma masofasi (zayavka_distance)</label>
                        <input
                          disabled
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          :value="list.zayavka_distance | mask"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <div class="card-toolbar">
                        <div
                          type="button"
                          @click="getContract"
                          class="btn btn-primary font-weight-bolder"
                        >
                          <span class="svg-icon svg-icon-white"
                            ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                                  fill="#000000"
                                />
                              </g></svg
                            ><!--end::Svg Icon--></span
                          >

                          Shartnoma
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pb-5" data-wizard-type="step-content">
                <div class="row flex-wrap">
                  <div class="col-4">
                    <div class="form-group">
                      <label>Mijoz nomi</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrTnnId.client_name"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Shartnoma raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="list.contract_number"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Reg Raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getRegNum"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Brand name/model</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getNameModel"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>TTN reg raqami</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrTnnId.reg_number"
                      />
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group">
                      <label>Kiritilgan</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrTnnId.created_at | formatDate"
                      />
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Yangilangan</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrTnnId.updated_at | formatDate"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <div class="card-toolbar">
                        <a
                          @click="getExcel(getCurrTnnId.zayavka)"
                          class="btn btn-primary font-weight-bolder"
                        >
                          <span class="svg-icon svg-icon-white"
                            ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                                  fill="#000000"
                                />
                              </g></svg
                            ><!--end::Svg Icon--></span
                          >

                          Export EXCEL</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
.gallery {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
  &__item {
    width: 150px;
    height: auto;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
    .download {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTWizard from '@/assets/js/components/wizard'
import axios from 'axios'
import { numberWithSpaces } from '@/utils'

export default {
  name: 'Wizard-3',
  data() {
    return {
      index: null,
      loading: true,
      list: {},
      contractid: ''
    }
  },
  filters: {
    // mask: function(val) {
    //   return val.toLocaleString('zh-ZH')
    // }
  },
  created() {
    this.$store.dispatch('getZayavkaDetails', this.$route.params.id)
    this.$store.dispatch('getTnnById', this.$route.params.id)
  },
  watch: {
    getCurrZayavka(val) {
      this.list = val
      this.loading = false
    },

    loading(val) {
      if (!val) {
        this.initWizard()
      }
    }
  },
  computed: {
    getCurrZayavka() {
      return this.$store.state.requests.zayavkaById
    },

    getCurrZayavkaImg() {
      var data = this.$store.state.requests.zayavkaById.zayavka_fotoreports

      const map1 = data.map((x) => x.foto_report)

      return map1
    },
    getCurrTnnId() {
      return this.$store.state.requests.alltnnid
    },
    getRegNum() {
      if (
        this.getCurrZayavka.truck !== null &&
        this.getCurrZayavka.truck !== undefined
      ) {
        return (
          this.getCurrZayavka.truck.reg_number_region +
          ' ' +
          this.getCurrZayavka.truck.reg_number
        )
      } else return "Malumot yo'q"
    },
    getNameModel() {
      if (
        // eslint-disable-next-line no-extra-boolean-cast
        Boolean(this.getCurrZayavka.truck)
      ) {
        if (this.getCurrZayavka.truck.truck_type !== undefined) {
          const data =
            this.getCurrZayavka.truck.truck_type.brand.name +
            ' ' +
            this.getCurrZayavka.truck.truck_type.model +
            " (yuk ko'tarish: " +
            this.getCurrZayavka.truck.truck_type.capacity_kg +
            ' kg' +
            ' hajmi: ' +
            this.getCurrZayavka.truck.truck_type.capacity_m3 +
            ' m3)'
          return data
        } else {
          return "Malumot yo'q"
        }
      } else {
        return "Malumot yo'q"
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Buyurtma malumotlari' }])
    // Initialize form wizard
    this.initWizard()
  },
  methods: {
    numberWithSpaces,

    initWizard() {
      this.$nextTick(() => {
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })

        // Validation before going to next page
        wizard.on('beforeNext', function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })
      })
    },

    downImg(val) {
      event.preventDefault()
      console.log(val)
      axios({
        url: val,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'image.jpg')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    },
    getExcel(val) {
      axios
        .get('zayavkas/ttn/reporttoexcel/' + val + '/', {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'TTN')
          document.body.appendChild(link)
          link.click()
        })
    },
    getContract() {
      this.$router.replace(
        {
          path: `/newcontractdetails/${this.list.contract}`
        },
        () => {
          this.$router.go(0)
        }
      )
    }
  }
}
</script>
<style scoped>
.slider {
  position: relative;
}

.wizard-step {
  padding: 0;
}
.photo__item {
  width: 30%;
}
.photo__item img {
  max-width: 100%;
  height: auto;
}
</style>
